import moment from "moment";

import { dataEmbeddingPoint } from "@/services";

interface StatData {
  uid: string;
  ct: string;
  ci: string;
  mac: string;
  sv: string;
  fv: string;
  pt: string;
  pageid: string;
  moduleid: string;
  ca: string;
  eventid: string;
  eventattr?: any;
}

const baseFastClassWorkConfig: StatData = {
  uid: "",
  ct: "",
  ci: "",
  mac: "",
  sv: "",
  fv: "",
  pt: "",
  pageid: "",
  moduleid: "",
  ca: "",
  eventid: "",
  eventattr: "",
};

const baseReportData = {
  teacher_id: 0,
  school_id: 0,
};

const initStatConfig = (store: any) => {
  Object.assign(baseFastClassWorkConfig, {
    uid: store.state.pcUserIdAES,
    ct: "96",
    ci: store.state.mac,
    mac: store.state.mac,
    sv: store.state.version,
    fv: store.state.hardwareModel,
    pt: "pc",
    pageid: "fastClassWork_page",
    moduleid: "fastClassWork",
  });

  Object.assign(baseReportData, {
    school_id: store.state.schoolId,
    teacher_id: store.state.teacherId,
  });
};

const reportStat = (event: string, data: any) => {
  return new Promise((resolve, reject) => {
    const android =
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1;
    if (!android) {
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // pc数据上报
      const reportData: StatData = {
        ...baseFastClassWorkConfig,
        ca,
        eventid: event,
        eventattr: { ...baseReportData, ...data },
      };
      console.log("reportData:", reportData);
      const body = encodeURIComponent(JSON.stringify(reportData));
      dataEmbeddingPoint(body)
        .then(() => resolve(""))
        .catch(() => reject(""));
    } else {
      resolve("");
    }
  });
};

export enum FastClassWorkEvent {
  FASTCLASSWORK_CREATE = "fastClassWork_create",
  FASTCLASSWORK_FINISH_MARK = "fastClassWork_finish_mark",
  FASTCLASSWORK_EDIT = "fastClassWork_edit",
  FASTCLASSWORK_DELETE = "fastClassWork_delete",
  FASTCLASSWORK_RECORD_PDF_UPLOAD = "fastClassWork_record_pdf_upload",
  FASTCLASSWORK_RECORD_BATCH_UPLOAD = "fastClassWork_record_batch_upload",
  FASTCLASSWORK_RECORD_EXCEL_UPLOAD = "fastClassWork_record_excel_upload",
  FASTCLASSWORK_RECORD_SINGLE_UPLOAD = "fastClassWork_record_single_upload",
  FASTCLASSWORK_RECORD_MARK = "fastClassWork_record_mark",
}

export { initStatConfig, reportStat };
