// 选择框项目
const SelectItems = {
  CLASS: "class",
  SUBJECT: "subject",
  STATUS: "status",
};

const FastClassWorkStatus = [
  {
    label: "所有",
    value: -1,
  },
  {
    label: "未开始",
    value: 0,
  },
  {
    label: "进行中",
    value: 1,
  },
  {
    label: "截止提交",
    value: 2,
  },
  {
    label: "完成评阅",
    value: 3,
  },
];

const FastClassWorkStatusMap = {
  NO_SUBMIT: 0,
  SUBMITTING: 1,
  END_SUBMIT: 2,
  COMPLETE_REVIEW: 3,
};

const GradeName = [
  "一年级",
  "二年级",
  "三年级",
  "四年级",
  "五年级",
  "六年级",
  "七年级",
  "八年级",
  "九年级",
  "高一",
  "高二",
  "高三",
  "大一",
  "大二",
  "大三",
  "大四",
];

const FormType = {
  CREATE: "create",
  EDIT: "edit",
};

const ReviewTypes = {
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  STUDENT_RECORD_FILE: "student_record_file",
};

const UploadTaskStatus = {
  PENDING: "pending",
  START: "start",
  DOING: "doing",
  DONE: "done",
  FAILED: "failed",
};

const UploadType = {
  SINGLE: "single",
  PDF: "pdf",
  BATCH: "batch",
  EXCEL: "excel",
  MODAL_SINGLE: "modal_single",
};

const AssessRecordType = {
  TEACHER: 0,
  STUDENT_EACH: 1,
  STUDENT_SELF: 2,
};

const ReviewDefaultTabName = {
  IMAGE: "图片",
  AUDIO: "音频",
  VIDEO: "视频",
  STUDENT_RECORD_FILE: "视频",
};

export {
  SelectItems,
  FastClassWorkStatus,
  GradeName,
  FormType,
  ReviewTypes,
  UploadTaskStatus,
  UploadType,
  AssessRecordType,
  ReviewDefaultTabName,
  FastClassWorkStatusMap,
};
