
interface Statistics {
  excellentNum: number;
  goodNum: number;
  mediumNum: number;
  poorNum: number;
  allNum: number;
  finishNum: number;
  avgScore: number;
  maxScore: number;
  middleScore: number;
  minScore: number;
}
interface Student {
  id: number;
  name: string;
  score?: number;
  avatar?: string;
}

import { computed, defineComponent, onMounted, ref } from "vue";
import * as echarts from "echarts";
import {
  getFastClassWorkRank,
  getFastClassWorkRecords,
  getFastClassWorkStatistics,
} from "@/services/homework";
import _ from "lodash";

export default defineComponent({
  props: {
    fastClassWorkId: {
      type: Number,
      default: -1,
    },
    fastClassWorkName: {
      type: String,
      default: "",
    },
  },
  emits: ["update:visible"],
  setup(props, ctx) {
    const loading = ref(false);
    const title = computed(() => props.fastClassWorkName);
    const chartRef = ref();
    const statistics = ref<Statistics>({
      excellentNum: 0,
      goodNum: 0,
      mediumNum: 0,
      poorNum: 0,
      allNum: 0,
      finishNum: 0,
      avgScore: 0,
      maxScore: 0,
      middleScore: 0,
      minScore: 0,
    });
    const noSubmitStudents = ref<Student[]>([]);
    const noSubmitStudentsListVisible = ref(false); // 显示未提交学生列表
    const rankStudents = ref<Student[]>([]);
    const rankTop3Students = computed<Student[]>(() => {
      if (rankStudents.value) {
        if (rankStudents.value.length < 3) {
          return [...rankStudents.value];
        } else {
          return rankStudents.value.slice(0, 3);
        }
      } else {
        return [];
      }
    });

    const rankAfterStudents = computed<Student[]>(() => {
      if (rankStudents.value && rankStudents.value.length > 3) {
        return rankStudents.value.slice(3, rankStudents.value.length);
      } else {
        return [];
      }
    });

    const formatScore = (score: any) => {
      const _score = score || 0;
      return (_score + "").indexOf(".") === -1
        ? _score
        : Number(score).toFixed(1);
    };

    const showNoSubmitStudentsList = () => {
      noSubmitStudentsListVisible.value = !noSubmitStudentsListVisible.value;
    };

    const initChartPie = () => {
      const seriesData = [
        {
          value: statistics.value.excellentNum,
          name: "85分以上",
          label: "excellent",
          itemStyle: { color: "#4dd6f9" },
          percent: 0,
        },
        {
          value: statistics.value.goodNum,
          name: "70-84分",
          label: "good",
          itemStyle: { color: "#fecd35" },
          percent: 0,
        },
        {
          value: statistics.value.mediumNum,
          name: "60-69分",
          label: "medium",
          itemStyle: { color: "#59ce64" },
          percent: 0,
        },
        {
          value: statistics.value.poorNum,
          name: "59分以下",
          label: "poor",
          itemStyle: { color: "#7070cf" },
          percent: 0,
        },
      ]
        .filter((data) => data.value > 0)
        .sort((seriesA, seriesB) => seriesA.value - seriesB.value);

      // 计算百分比，百分比保留整数
      const _seriesData: any = [];
      seriesData.forEach((data, index) => {
        if (index + 1 === seriesData.length) {
          let _percent = 100;
          for (let i = 0; i < seriesData.length; i++) {
            _percent -= seriesData[i].percent;
          }
          data.percent = _percent;
          _seriesData.push(data);
        } else {
          data.percent = Math.round(
            (data.value / (statistics.value.allNum || 1)) * 100
          );
          _seriesData.push(data);
        }
      });

      const option: any = {
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },

        label: {
          formatter: function (pram: any) {
            return (
              "{a|" +
              pram.data.name +
              " }\n{b|" +
              pram.data.value +
              "人 " +
              pram.data.percent +
              "% }"
            );
          },
          padding: [0, -58, 8, -58],
          rich: {
            a: {
              fontSize: 14,
              color: "#000",
              fontWeight: 500,
              lineHeight: 28,
              align: "right",
            },
            b: {
              color: "#33c797",
            },
          },
        },
        series: [
          {
            type: "pie",
            startAngle: 45,
            radius: ["45%", "62%"],
            avoidLabelOverlap: true,
            minAngle: 5,
            data: _seriesData,
            labelLine: {
              show: true,
              length: 14, // 第一段线长
              length2: 50, // 第二段线长
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      const myChart = echarts.init(chartRef.value as HTMLElement);
      myChart.setOption(option);
    };

    const closeModal = () => {
      ctx.emit("update:visible", false);
    };

    const initResultReport = async () => {
      try {
        const statisticsData = (await getFastClassWorkStatistics(
          props.fastClassWorkId
        )) as any;
        const {
          a_num,
          b_num,
          c_num,
          d_num,
          all_num,
          finish_num,
          max_score,
          min_score,
          middle_score,
          avg_score,
        } = statisticsData?.data || {};
        _.merge(statistics.value, {
          excellentNum: a_num,
          goodNum: b_num,
          mediumNum: c_num,
          poorNum: d_num,
          allNum: all_num,
          finishNum: finish_num,
          maxScore: formatScore(max_score),
          minScore: formatScore(min_score),
          middleScore: formatScore(Number(middle_score)),
          avgScore: formatScore(Number(avg_score)),
        });

        const noSubmitStudentsData = (await getFastClassWorkRecords({
          fast_classwork_id: props.fastClassWorkId,
          status: 0,
        })) as any;
        noSubmitStudents.value.length = 0;
        noSubmitStudentsData?.data?.forEach((stu: any) => {
          noSubmitStudents.value.push({
            id: stu.id,
            name: stu.student_name,
          });
        });

        const rankData = (await getFastClassWorkRank(
          props.fastClassWorkId
        )) as any;

        rankStudents.value.length = 0;
        rankData?.data.forEach((data: any) => {
          rankStudents.value.push({
            id: data?.student?.id,
            name: data?.student?.name,
            score: data?.score,
            avatar: data?.student?.avatar,
          });
        });
      } catch (err) {
        console.error("获取作业报告失败", err);
      }
    };
    onMounted(async () => {
      loading.value = true;
      try {
        await initResultReport();
        initChartPie();
      } catch (err) {
        loading.value = false;
      }
      loading.value = false;
    });

    return {
      loading,
      title,
      statistics,
      chartRef,
      noSubmitStudents,
      noSubmitStudentsListVisible,
      rankTop3Students,
      rankAfterStudents,
      closeModal,
      formatScore,
      showNoSubmitStudentsList,
    };
  },
});
